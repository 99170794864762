import Dropzone, { type DropzoneFile } from 'dropzone'
import axios from 'axios'
import { ref } from 'vue'
import previewTemplate from '@/ts/components/uploaders/PreviewTemplate.html?raw'

Dropzone.autoDiscover = false

export interface DropzoneFileWithUploadURL extends DropzoneFile {
  path: string
}

export type DropzoneInstance = ReturnType<typeof useDropzone>

const signedUrls = new Map<DropzoneFileWithUploadURL, { url: string }>()

export const useDropzone = (options: Dropzone.DropzoneOptions = {}) => {
  const defaultOptions: Dropzone.DropzoneOptions = {
    paramName: 'file', //name that will be used to transfer the file
    timeout: undefined,
    autoProcessQueue: false,
    maxFiles: 20,
    method: 'PUT',
    url: 'https://s3.amazonaws.com',
    previewTemplate,
    async accept(file: DropzoneFileWithUploadURL, done: (error?: string | Error) => void) {
      const { data } = await axios.post(`/api/file-signed-url`, {
        filePath: file.name,
        contentType: file.type
      })
      file.path = data.data.path

      signedUrls.set(file, {
        url: data.data.url
      })
      done()
    },
    sending(file, xhr) {
      // https://github.com/dropzone/dropzone/issues/1855
      const _send = xhr.send
      xhr.send = () => {
        _send.call(xhr, file)
      }
    }
  }

  Object.assign(defaultOptions, options)

  const processing = ref(false)
  let _instance: Dropzone | null = null

  const init = (elem: HTMLElement) => {
    _instance = new Dropzone(elem, defaultOptions)
    on('processing', (file) => {
      processing.value = true
      const signedUrl = signedUrls.get(file as DropzoneFileWithUploadURL)
      if (_instance && signedUrl) {
        _instance.options.url = signedUrl.url
        _instance.options.headers = {
          ..._instance.options.headers,
          'cache-control': 'max-age=604800',
          'content-type': (file as DropzoneFileWithUploadURL).type
        }
      }
    })

    if (options.autoProcessQueue !== false) {
      on('queuecomplete', () => {
        processing.value = false
      })
    }
  }

  const on = (event: string, callback: (...args: unknown[]) => void) => {
    if (_instance) {
      _instance.on(event, callback)
    }
  }

  const processQueue = () => {
    if (_instance) {
      processing.value = true
      _instance.getQueuedFiles().forEach((file) => {
        _instance?.processFile(file)
      })
    }
  }

  const reset = () => {
    if (_instance) {
      // cleared signedUrls
      _instance.getAcceptedFiles().forEach((file) => {
        if (signedUrls.has(file as DropzoneFileWithUploadURL)) {
          signedUrls.delete(file as DropzoneFileWithUploadURL)
        }
      })
      _instance.removeAllFiles()
    }
  }

  const hasFiles = () => {
    if (_instance) {
      return _instance.getQueuedFiles().length > 0
    }
    return false
  }

  return {
    options,
    processing,
    signedUrls,
    hasFiles,
    init,
    on,
    processQueue,
    reset
  }
}
